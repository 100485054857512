import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Button, Can } from '..';
import { formatDate, titleCase } from '../../lib/utils';
import DepotIcon from '../../static/icons/depot.svg';
import RegionIcon from '../../static/icons/region.svg';
import SkuIcon from '../../static/icons/sku.svg';
import WaiverTypeIcon from '../../static/icons/waiver-type.svg';
import Close from '../icons/Close';
import './_waiverdetails.scss';

const WaiverDetails = ({ isOpen, toggle, waiver, updateStatus }) => {
  const closeBtn = (
    <Button onClick={toggle} color="link" size="small" className="p-0">
      <Close />
    </Button>
  );

  return (
    <Modal
      isOpen={isOpen}
      className="waiver-details"
      returnFocusAfterClose={false}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle} className="p-4" close={closeBtn} tag="h2">
        Waiver ID: {waiver.id}
      </ModalHeader>
      <ModalBody className="p-0">
        <Container>
          <Row className="waiver-details-list-item py-4">
            <Col>
              Raised by:{' '}
              <span className="font-weight-bold">
                {titleCase(waiver.raised_by)}
              </span>
              <section>{formatDate(waiver.raised_at)}</section>
            </Col>
          </Row>

          {waiver.attended_to_by ? (
            <Row className="waiver-details-list-item py-4 border-0">
              <Col>
                {waiver.status} By:{' '}
                <span className="font-weight-bold">
                  {titleCase(waiver.attended_to_by)}
                </span>
                <section>{formatDate(waiver.attended_to_at)}</section>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </Container>

        <Container className="waiver-details-list">
          <Row className="waiver-details-list-item pt-4 border-0">
            <Col>
              <h3>Details</h3>
            </Col>
          </Row>
          <Row className="waiver-details-list-item">
            <Col xs={6} sm={5}>
              <img src={RegionIcon} alt="" className="pr-3" /> Region:
            </Col>
            <Col>
              {waiver.region && typeof waiver.region !== 'string'
                ? waiver.region.map((item) => `${titleCase(item.name)}, `)
                : waiver.region}
            </Col>
          </Row>
          <Row className="waiver-details-list-item">
            <Col xs={6} sm={5}>
              <img src={DepotIcon} alt="" className="pr-3" /> Depot:
            </Col>
            <Col>
              {waiver.depot && typeof waiver.depot !== 'string'
                ? waiver.depot.map((item) => `${titleCase(item.name)}, `)
                : waiver.depot}
            </Col>
          </Row>
          <Row className="waiver-details-list-item">
            <Col xs={6} sm={5}>
              <img src={WaiverTypeIcon} alt="" className="pr-3" /> Waiver Type:
            </Col>
            <Col>{waiver.waiver_type}</Col>
          </Row>
        </Container>

        <Container>
          <Row className="waiver-details-list-item border-0 my-2">
            <Col>
              <img src={SkuIcon} alt="" className="pr-3" /> SKU
            </Col>
          </Row>
        </Container>

        <Container fluid className="waiver-details-list">
          {waiver.sku && typeof waiver.sku !== 'string' ? (
            waiver.sku.map((item, index) => (
              <Row className="waiver-details-list-item" key={index}>
                <Col xs={4} sm={3}>
                  {item.sku_id}
                </Col>
                <Col>{item.product_name}</Col>
              </Row>
            ))
          ) : (
            <Row className="waiver-details-list-item">
              <Col>{waiver.sku} SKUs and products</Col>
            </Row>
          )}
        </Container>
        {waiver.comment ? (
          <section className="comment">
            <h3>
              <span>Comments</span>
            </h3>
            <p>{waiver.comment}</p>
          </section>
        ) : (
          ''
        )}
      </ModalBody>

      {waiver.status === 'Pending' ? (
        <Can
          perform="UPDATE_WAIVER_STATUS"
          yes={() => (
            <ModalFooter>
              <Button
                onClick={() => {
                  updateStatus('Approve', waiver);
                  toggle();
                }}
              >
                Approve
              </Button>
              <Button
                outline
                className="ml-auto"
                onClick={() => {
                  updateStatus('Disapprove', waiver);
                  toggle();
                }}
              >
                Disapprove
              </Button>
            </ModalFooter>
          )}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

WaiverDetails.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  comment: PropTypes.string,
};

export default WaiverDetails;
