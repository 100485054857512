import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import './_error404.scss';

const Error404 = () => (
  <Container fluid tag="main">
    <Row>
      <Col>
        <Card className="border-0 text-center shadow-sm p-5 snow-bg error404">
          <h2 className="display-1 text-center mb-3">Oops!</h2>
          <h3 className="text-center lead">404 - Page Not Found</h3>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Error404;
