import React, { useState } from 'react';
import {AppContextProvider} from '.';

const ContextProvider = ({ children }) => {
  const existingAuthData = JSON.parse(localStorage.getItem('auth'));
  const [authData, setAuthData] = useState(existingAuthData);

  const setSession = data => {
    data
      ? localStorage.setItem('auth', JSON.stringify(data))
      : localStorage.clear();
    setAuthData(data);
  };

  const logout = () => {
    setSession();
  };
  
  const context = {
    ...authData,
    setSession,
    logout
  };

  return (
    <AppContextProvider value={ context }> 
      {children}
    </AppContextProvider>
  );
}

export default ContextProvider;