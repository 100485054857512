import React, { useRef } from 'react';
import ReactSelect from 'react-select';
import { FormGroup } from 'reactstrap';
import './_multiselect.scss';

const theme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "rgba(42, 23, 112, 0.25)",
    primary: "#5532d5",

    // All possible overrides
    // primary: '#2684FF',
    // primary75: '#4C9AFF',
    // primary50: '#B2D4FF',
    // primary25: '#DEEBFF',

    // danger: '#DE350B',
    // dangerLight: '#FFBDAD',

    // neutral0: 'hsl(0, 0%, 100%)',
    // neutral5: 'hsl(0, 0%, 95%)',
    // neutral10: 'hsl(0, 0%, 90%)',
    // neutral20: 'hsl(0, 0%, 80%)',
    // neutral30: 'hsl(0, 0%, 70%)',
    // neutral40: 'hsl(0, 0%, 60%)',
    // neutral50: 'hsl(0, 0%, 50%)',
    // neutral60: 'hsl(0, 0%, 40%)',
    // neutral70: 'hsl(0, 0%, 30%)',
    // neutral80: 'hsl(0, 0%, 20%)',
    // neutral90: 'hsl(0, 0%, 10%)',
  }
  // Other options you can use
  // borderRadius: 4
  // baseUnit: 4,
  // controlHeight: 38
  // menuGutter: baseUnit * 2
});

const MultiSelect = (props) => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: 'Select All',
  };

  const isSelectAllSelected = () => {
    return (
      props.options.length !== 0 &&
      valueRef.current.length === props.options.length
    );
  };

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === 'deselect-option' &&
        option.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === 'deselect-option' &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta,
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  return (
    <FormGroup className="gbnl-multi-select">
      <label>
        <span>{props.label}</span>
        <ReactSelect
          isOptionSelected={isOptionSelected}
          options={getOptions()}
          value={getValue()}
          onChange={onChange}
          isMulti
          maxMenuHeight="150px"
          isSearchable={true}
          isDisabled={props.isDisabled}
          placeholder={`Select ${props.label}`}
          theme={theme}
        />
      </label>
    </FormGroup>
  );
};

export default MultiSelect;
