import * as React from 'react';

const Logout = (props) => {
  return (
    <svg
      width={19}
      height={17}
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.994 12.91a.773.773 0 001.094-1.093L3.543 9.273h8.953a.773.773 0 000-1.546H3.543l2.545-2.544A.773.773 0 004.994 4.09L1.131 7.954a.77.77 0 000 1.093l3.863 3.863zm7.824 3.317c0 .427.346.773.772.773h3.092a2.325 2.325 0 001.635-.67A2.285 2.285 0 0019 14.703V2.318A2.31 2.31 0 0018.32.68 2.309 2.309 0 0016.683 0H13.59a.772.772 0 000 1.545h3.092c.426 0 .773.348.773.773v12.384a.763.763 0 01-.773.752H13.59a.773.773 0 00-.772.773z"
        fill="#160C3B"
      />
    </svg>
  );
};

export default Logout;
