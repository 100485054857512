import useAppContext from '../../context';
import rules from '../../lib/rules';

const Can = ({ perform, yes, no }) => {
  const { loggedInUser } = useAppContext();
  const { companyId, roleId } = loggedInUser;

  const companyRoles = rules.find((rule) => rule.id === companyId);
  const rolePermissions = companyRoles.roles.find((item) => item.id === roleId);

  const { permissions } = rolePermissions;

  const check = (action) => {
    if (!permissions) {
      return false;
    }

    if (
      permissions.includes(action) ||
      permissions.includes(action.toLowerCase())
    ) {
      return true;
    }

    return false;
  };

  return check(perform) ? yes() : no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
