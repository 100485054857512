import { Fragment, useCallback, useEffect, useState } from 'react';
import { Loader, Select } from '../..';
import { fetchAllRegion } from '../../../api/waiver';
import { ERROR } from '../../../config/constants';
import { handleResponseError } from '../../../lib/utils';

const SelectRegion = ({
  formData,
  setFormData,
  allowedValues,
  setAllowedValues,
}) => {
  const [getRegionStatus, setGetRegionStatus] = useState({});
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');

  const getRegions = useCallback(async () => {
    setGetRegionStatus({
      statusType: 'loading',
    });

    const response = await fetchAllRegion();
    const responseError = handleResponseError(response);

    if (responseError) {
      setGetRegionStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setGetRegionStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setRegions(response.data.data);
    setGetRegionStatus({});
  }, []);

  const regionSelection = (e) => {
    let { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      region: value === 'All' ? value : [value],
      depot: [],
      sku: []
    }));

    updateAllowedTypes(value);
  };

  const updateAllowedTypes = (value) => {
    const waiver_type_id = formData.waiver_type_id;

    if (value !== 'All' && waiver_type_id === '2') {
      setAllowedValues((prevState) => ({
        ...prevState,
        depot: ['all'],
      }));
    } else if (value === 'All') {
      setAllowedValues((prevState) => ({
        ...prevState,
        depot: ['all'],
      }));
    } else {
      setAllowedValues((prevState) => ({
        ...prevState,
        depot: ['all', 'one', 'many'],
      }));
    }
  };

  useEffect(() => {
    getRegions();
  }, [getRegions]);

  useEffect(() => {
    setSelectedRegion(formData.region || '');
  }, [formData]);

  return (
    <Fragment>
      {getRegionStatus && getRegionStatus.statusType === 'loading' ? (
        <Loader />
      ) : (
        ''
      )}
      <Select
        label="Region"
        value={selectedRegion}
        onChange={regionSelection}
        required
        disabled={!allowedValues.region}
        errorMessage={
          getRegionStatus.statusType === 'error'
            ? 'There was an error loading regions.'
            : ''
        }
      >
        <option value="" disabled>
          Select Region
        </option>
        {allowedValues.region && allowedValues.region.includes('all') ? (
          <option value="All">All</option>
        ) : (
          ''
        )}
        {allowedValues.region && allowedValues.region.includes('one')
          ? regions.map((region) => (
              <option value={region.id} key={region.id}>
                {region.name}
              </option>
            ))
          : ''}
      </Select>
    </Fragment>
  );
};

export default SelectRegion;
