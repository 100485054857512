import React from 'react';
import Empty from '../icons/Empty';
import './_emptywaiver.scss';

const EmptyWaiver = () => (
  <section className="empty-waiver">
    <Empty />
    <h2>No Raised Waiver</h2>
    <p>All the raised Waivers will be show here</p>
  </section>
);

EmptyWaiver.defaultProps = {};

EmptyWaiver.propTypes = {};

export default EmptyWaiver;
