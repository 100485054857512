import { Fragment, useCallback, useEffect, useState } from 'react';
import { Loader, Select } from '../..';
import { fetchWaiverTypes } from '../../../api/waiver';
import { ERROR } from '../../../config/constants';
import { handleResponseError } from '../../../lib/utils';

const SelectWaiverType = ({ formData, setFormData, setAllowedValues }) => {
  const [getWaiverTypeStatus, setGetWaiverTypeStatus] = useState({});
  const [waiverTypes, setWaiverTypes] = useState([]);
  const [selectedWaiverType, setSelectedWaiverType] = useState('');

  const getWaiverTypes = useCallback(async () => {
    setGetWaiverTypeStatus({
      statusType: 'loading',
    });

    const response = await fetchWaiverTypes();
    const responseError = handleResponseError(response);

    if (responseError) {
      setGetWaiverTypeStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setGetWaiverTypeStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setWaiverTypes(response.data.data);
    setGetWaiverTypeStatus({});
  }, []);

  const waiverTypeSelection = (e) => {
    let { value } = e.target;

    setSelectedWaiverType(value);

    setFormData({
      waiver_type_id: value,
      region: [],
      depot: [],
      sku: [],
    });

    updateAllowedTypes(value);
  };

  const updateAllowedTypes = (value) => {
    switch (+value) {
      case 1:
        // Force Majeure

        setAllowedValues({
          region: ['all', 'one'],
        });

        break;
      case 2:
        // Additional Demand

        setAllowedValues({
          region: ['all', 'one'],
          // depot: ['all'],
          // sku: ['all', 'one', 'many'],
        });

        break;
      case 3:
        // Product Recall

        setAllowedValues({
          region: ['all', 'one'],
          // depot: ['all', 'one', 'many'],
          // sku: ['all', 'one', 'many'],
        });

        break;
      case 4:
        // Public Holiday

        setAllowedValues({
          region: ['all', 'one'],
          // depot: ['all', 'one', 'many'],
          // sku: ['all'],
        });

        break;
      case 5:
        // Non Shipment

        setAllowedValues({
          region: ['all', 'one'],
          // depot: ['all'],
          // sku: ['all', 'one', 'many'],
        });

        break;
      case 6:
        // Production Issue

        setAllowedValues({
          region: ['all', 'one'],
          // depot: ['all'],
          // sku: ['all', 'one', 'many'],
        });

        break;
      case 7:
        // Price Change

        setAllowedValues({
          region: ['all'],
          // depot: ['all'],
          // sku: ['all', 'one', 'many'],
        });

        break;
      case 8:
        // Depot Maintenance

        setAllowedValues({
          region: ['one'],
          // depot: ['all', 'one', 'many'],
          // sku: ['all'],
        });

        break;
      case 9:
        // Police Investigation

        setAllowedValues({
          region: ['one'],
          // depot: ['all', 'one', 'many'],
          // sku: ['all'],
        });

        break;
      case 10:
        // Election

        setAllowedValues({
          region: ['all', 'one'],
          // depot: ['all', 'one', 'many'],
          // sku: ['all'],
        });

        break;
      default:
    }
  };

  useEffect(() => {
    getWaiverTypes();
    return () => {};
  }, [getWaiverTypes]);

  return (
    <Fragment>
      {getWaiverTypeStatus && getWaiverTypeStatus.statusType === 'loading' ? (
        <Loader />
      ) : (
        ''
      )}
      <Select
        label="Waiver Type"
        value={selectedWaiverType}
        onChange={waiverTypeSelection}
        required
        errorMessage={
          getWaiverTypeStatus.statusType === 'error'
            ? 'There was an error loading waiver types.'
            : ''
        }
      >
        <option value="" disabled>
          Select Waiver Type
        </option>

        {waiverTypes.map((waiver_type) => (
          <option value={`${waiver_type.id}`} key={waiver_type.id}>
            {waiver_type.type}
          </option>
        ))}
      </Select>
    </Fragment>
  );
};

export default SelectWaiverType;
