import { Fragment, useCallback, useEffect, useState } from 'react';
import { Loader, MultiSelect, Select } from '../..';
import { fetchRegionDepot } from '../../../api/waiver';
import { ERROR } from '../../../config/constants';
import { handleResponseError } from '../../../lib/utils';

const SelectDepot = ({
  formData,
  setFormData,
  allowedValues,
  setAllowedValues,
}) => {
  const [getDepotStatus, setGetDepotStatus] = useState({});
  const [depots, setDepots] = useState([]);
  const [selectedDepot, setSelectedDepot] = useState([]);

  const getDepots = useCallback(async (region) => {
    setGetDepotStatus({
      statusType: 'loading',
    });

    const depotArray = [];

    if (region === 'All') {
      setGetDepotStatus({});

      return;
    }

    const response = await fetchRegionDepot(region);
    const responseError = handleResponseError(response);

    if (responseError) {
      setGetDepotStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setGetDepotStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    response.data.data.forEach((depot) =>
      depotArray.push({ value: depot.id, label: depot.name }),
    );

    setDepots(depotArray);

    setGetDepotStatus({});
  }, []);

  const singleDepotSelection = (e) => {
    let { value } = e.target;
    setSelectedDepot(value);

    const selectedDepotArray = [];

    if(formData.region !== "All") {
      depots.forEach((depot) => selectedDepotArray.push(depot.value.toString()));
    }

    setFormData((prevState) => ({
      ...prevState,
      depot: selectedDepotArray.length ? selectedDepotArray : value,
      sku: [],
    }));

    updateAllowedTypes(value);
  };

  const multipleDepotSelection = (e) => {
    setSelectedDepot(e);

    const selectedDepotArray = [];

    e.forEach((depot) => selectedDepotArray.push(depot.value.toString()));

    setFormData((prevState) => ({
      ...prevState,
      depot: selectedDepotArray,
      sku: [],
    }));

    updateAllowedTypes(selectedDepotArray);
  };

  const updateAllowedTypes = (value) => {
    const { waiver_type_id } = formData;
    const requireAllDepotSku = ['1', '4', '8', '9', '10'];

    if (requireAllDepotSku.includes(waiver_type_id)) {
      setAllowedValues((prevState) => ({
        ...prevState,
        sku: ['all'],
      }));
    } else {
      setAllowedValues((prevState) => ({
        ...prevState,
        sku: ['one', 'many'],
      }));
    }
  };

  useEffect(() => {
    if (formData.region.length !== 0) {
      getDepots(formData.region);
    }
  }, [getDepots, formData.region]);

  useEffect(() => {
    setSelectedDepot([]);
  }, [formData.region]);

  return (
    <Fragment>
      {getDepotStatus && getDepotStatus.statusType === 'loading' ? (
        <Loader />
      ) : (
        ''
      )}

      {allowedValues.depot && allowedValues.depot.includes('many') ? (
        <MultiSelect
          label="Depot"
          options={depots}
          value={selectedDepot}
          onChange={multipleDepotSelection}
          isDisabled={depots.length === 0}
          allowAll={allowedValues.depot.includes('all')}
        />
      ) : (
        ''
      )}

      {!allowedValues.depot || !allowedValues.depot.includes('many') ? (
        <Select
          label="Depot"
          value={typeof selectedDepot === 'string' ? selectedDepot : ''}
          onChange={singleDepotSelection}
          required
          disabled={!allowedValues.depot}
          errorMessage={
            getDepotStatus.statusType === 'error'
              ? 'There was an error loading depot.'
              : ''
          }
        >
          <option value="" disabled>
            Select Depot
          </option>
          {allowedValues.depot && allowedValues.depot.includes('all') ? (
            <option value="All">All</option>
          ) : (
            ''
          )}
          {allowedValues.depot && allowedValues.depot.includes('one')
            ? depots.map((depot) => (
                <option value={depot.id} key={depot.id}>
                  {depot.name}
                </option>
              ))
            : ''}
        </Select>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default SelectDepot;
