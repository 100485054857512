import React from 'react';
import { Button as Btn } from 'reactstrap';
import './_button.scss';

const Button = ({ loading, disabled, children, ...restProps }) => (
  <Btn
    color="primary"
    disabled={disabled || loading}
    className="gbnl-btn"
    {...restProps}
  >
    {loading ? (
      <div className="loading-state">
        <span
          className="spinner-border"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    ) : (
      ''
    )}
    {children}
  </Btn>
);

export default Button;
