import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormFeedback,
  FormGroup,
  FormText,
  Input as BootInput,
} from 'reactstrap';
import './_input.scss';

const Input = ({
  label,
  error,
  errorMessage,
  helpText,
  type,
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormGroup className="gbnl-input">
      <label>
        <span>{label}</span>

        {type === 'password' ? (
          <div className="password-field">
            <BootInput
              type={showPassword ? 'text' : 'password'}
              {...restProps}
              invalid={!!errorMessage}
            />
            <label className="field-toggler">
              <input
                type="checkbox"
                onChange={togglePasswordVisibility}
                tabIndex={-1}
              />{' '}
              Show
            </label>
          </div>
        ) : (
          <BootInput type={type} {...restProps} invalid={!!errorMessage} />
        )}
        <FormFeedback>{errorMessage || `Error with ${label}`}</FormFeedback>
        {helpText ? <FormText>{helpText}</FormText> : ''}
      </label>
    </FormGroup>
  );
};

Input.defaultProps = {
  error: false,
};

Input.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
};

export default Input;
