import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';
import { Alert, Form } from 'reactstrap';
import { login } from '../../api/auth';
import { AuthLayout, Button, Input, Loader } from '../../components';
import { ERROR, SUCCESS } from '../../config/constants';
import useAppContext from '../../context';
import { handleResponseError, sentenceCase } from '../../lib/utils';
import './_login.scss';

const Login = () => {
  const { setSession } = useAppContext();
  const [status, setStatus] = useState({});
  const [formState, { email, password }] = useFormState();
  const formFields = {
    email: 'email',
    password: 'password',
  };

  const loginUser = async (e) => {
    e.preventDefault();

    setStatus({
      statusType: 'loading',
    });

    const response = await login(
      formState.values.email,
      formState.values.password,
    );
    const responseError = handleResponseError(response);
    setStatus({});

    if (responseError) {
      setStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    const { token, user } = response.data;

    const { id, activate, company, email, firstName, lastName, role } = user;

    const loggedInUser = {
      id,
      activate,
      company: company.name,
      companyId: company.id,
      email,
      firstName: sentenceCase(firstName),
      lastName: sentenceCase(lastName),
      role: role.roleName,
      roleId: role.id,
    };

    if (!activate) {
      setStatus({
        statusType: 'error',
        message: ERROR.NOT_ACTIVATED,
      });
      return;
    }

    setStatus({
      statusType: 'success',
      message: SUCCESS.LOGGED_IN,
    });

    setTimeout(() => {
      setSession({ token, loggedInUser });
    }, 1500);
  };

  return (
    <AuthLayout title={'Login'}>
      <Form onSubmit={loginUser}>

        {status && status.statusType === 'loading' ? <Loader absolute /> : ''}
        {status && status.statusType === 'error' ? (
          <Alert color="danger" children={status.message} />
        ) : (
          ''
        )}
        {status && status.statusType === 'success' ? (
          <Alert color="success" children={status.message} />
        ) : (
          ''
        )}
        <Input
          label="Your Email Address"
          required
          {...email(formFields.email)}
        />
        <Input
          label="Your Password"
          required
          {...password(formFields.password)}
        />
        <Button type={'submit'} loading={status.statusType === 'loading'} block>
          Login
        </Button>
      </Form>
      <p className="mt-3 mb-0 text-center small">
        <Link to="/reset-password">Forget Password</Link>
      </p>
    </AuthLayout>
  );
};

export default Login;
