import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem, Row } from 'reactstrap';
import { Error404, ProfileSettings, UserSettings } from '..';
import { Can } from '../../components';
import './_settings.scss';

const Settings = ({ match }) => {

  return (
    <section>
      <header className="header sub-header mt-n5 mb-5">
        <Container fluid>
          <Row>
            <Navbar light expand="md" className="w-100">
              <Nav navbar className="w-100">
                <NavItem>
                  <NavLink to={`${match.url}/profile`}>Profile</NavLink>
                </NavItem>

                <Can
                  perform="VIEW_USERS"
                  yes={() => (
                    <NavItem>
                      <NavLink to={`${match.url}/user`}>Users</NavLink>
                    </NavItem>
                  )}
                />
              </Nav>
            </Navbar>
          </Row>
        </Container>
      </header>

      <Container fluid tag="main">
        <Row className="d-flex justify-content-center">
          <Switch>
            <Route path="/settings/" exact>
              <Redirect
                to={{
                  pathname: `/settings/profile`,
                }}
              />
            </Route>
            <Route
              exact
              path={`${match.path}/profile`}
              component={ProfileSettings}
            />

            <Can
              perform="VIEW_USERS"
              yes={() => (
                <Route
                  exact
                  path={`${match.path}/user`}
                  component={UserSettings}
                />
              )}
            />
            <Route path="" exact component={Error404} />
          </Switch>
        </Row>
      </Container>
    </section>
  );
};

export default Settings;
