import * as React from 'react';

const Empty = (props) => {
  return (
    <svg
      width={100}
      height={100}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M63.447 72.329v5.795a2.022 2.022 0 01-2.019 2.029H17.295a2.031 2.031 0 01-2.029-2.029V21.66a2.03 2.03 0 012.028-2.025H61.43c1.112 0 2.018.905 2.018 2.025v8.432l6.272 6.272V19.969c0-3.843-3.126-6.969-6.973-6.969H15.978C12.132 13 9 16.126 9 19.969v59.845c0 3.843 3.132 6.973 6.979 6.973h46.767a6.982 6.982 0 006.974-6.973V66.056l-6.273 6.273z"
        fill="#2F1A7A"
      />
      <path
        d="M88.744 61.678L78.27 51.208l10.462-10.466a5.757 5.757 0 00-.004-8.14 5.766 5.766 0 00-8.144.004L70.13 43.066 59.68 32.61a5.766 5.766 0 00-8.141-.004 5.763 5.763 0 00-.003 8.144L61.99 51.21 51.525 61.674a5.762 5.762 0 00.004 8.144 5.754 5.754 0 004.065 1.68 5.74 5.74 0 004.074-1.688L70.13 59.348l10.466 10.47a5.744 5.744 0 004.073 1.687 5.746 5.746 0 004.066-1.683 5.767 5.767 0 00.009-8.144z"
        fill="#2F1A7A"
      />
    </svg>
  );
};

export default Empty;
