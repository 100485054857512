import * as React from 'react';

const Bell = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.797 15.744h0c-.657-.911-.997-1.945-.997-2.989v-1.88c0-2.54-1.93-4.626-4.5-5.409V4.5c0-1.185-1.12-2-2.3-2-1.18 0-2.3.815-2.3 2v.966c-2.571.783-4.5 2.869-4.5 5.409v1.88c0 1.044-.34 2.077-.996 2.988 0 0 0 0 0 0l-1.546 2.146a.821.821 0 00-.013.952l.414-.282-.414.282a.97.97 0 00.804.409H20.55a.972.972 0 00.804-.407h0a.823.823 0 00-.011-.952h-.001l-1.545-2.147z"
        stroke="#413D45"
      />
      <path
        d="M9.165 19.5c.508.884 1.583 1.5 2.835 1.5 1.251 0 2.326-.616 2.834-1.5H9.165z"
        fill="#413D45"
      />
    </svg>
  );
};

export default Bell;
