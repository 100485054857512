import { ERROR } from '../config/constants';
import { DateTime } from 'luxon';
import Papa from 'papaparse';

export const handleApiErrors = (error) => {
  let message = error.message || ERROR.REQUEST_ERROR;

  if (error.response) {
    if (error.response.data) {
      return error.response.data;
    }
  }

  return message;
};

export const handleResponseError = (response) => {
  if (!response) {
    return ERROR.RESPONSE_ERROR;
  }

  if (!response.success && response.message) {
    return sentenceCase(response.message);
  }

  if (response.errors || response.error) {
    return ERROR.RESPONSE_ERROR;
  }
};

export const sentenceCase = (str) => {
  if (typeof str !== 'string') return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const titleCase = (str) => {
  if (typeof str !== 'string') return str;

  str = str.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
};

export const compareValues = (value, compareAgainst) => {
  return value === compareAgainst;
};

export const extractNumber = (str) => {
  if (typeof str !== 'string') return str;

  return parseInt(str.replace(/[^0-9]/g, ''));
};

export const hyphenate = (str) => {
  if (typeof str !== 'string') return str;

  return str.replace(/ /g, '-');
};

export const formatDate = (date) => {
  const isoDate = DateTime.fromISO(date);
  const humanReadable = isoDate.toLocaleString(DateTime.DATETIME_MED);

  return humanReadable;
};

export const checkTypeAndMap = (data) => {
  if (typeof data === 'string') return data;

  return data
}

const checkIfBrowserIsIE = () => {
  const userAgent = window.navigator.userAgent;
  const iE = userAgent.indexOf('MSIE ');

  return !!(
    iE > 0 ||
    !!navigator.userAgent.match(/Trident/) ||
    navigator.userAgent.match(/rv:11/)
  );
};

export const convertObjectArrayToCsv = (data, fileName, showHeaders=true) => {
  fileName = `${fileName} ${formatDate(DateTime.local().toString())}`;

  const csvData = Papa.unparse(data, {
    header: showHeaders,
  });

  if (checkIfBrowserIsIE()) {
    const iEWindow = window.open();
    iEWindow.document.write(`sep=,\r\n${csvData}`);
    iEWindow.document.close();
    iEWindow.document.execCommand('SaveAs', true, `${fileName}.csv`);
    iEWindow.close();
  } else {
    const uri = `data:application/csv;charset=utf-8,${encodeURI(csvData)}`;
    const link = document.createElement('a');
    link.href = uri;
    link.style = 'visibility:hidden';
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};