import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Alert,
  Card,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { fetchWaivers } from '../../api/waiver';
import {
  Badge,
  EmptyWaiver,
  ExportWaivers,
  FilterWaivers,
  Loader,
  Pagination,
  ReadComment,
  Table,
  WaiverDetails,
} from '../../components';
import Comment from '../../components/icons/Comment';
import VerticalDots from '../../components/icons/VerticalDots';
import { ERROR } from '../../config/constants';
import {
  extractNumber,
  formatDate,
  handleResponseError,
  titleCase,
} from '../../lib/utils';
import './_waiverstatuses.scss';

const WaiverStatuses = ({ match }) => {
  const history = useHistory();
  const location = useLocation();

  const initialPageNumber = location.search
    ? extractNumber(location.search)
    : 1;

  const [paginationMeta, setPaginationMeta] = useState({});
  const [getWaiversStatus, setGetWaiversStatus] = useState({});
  const [waivers, setWaivers] = useState([]);
  const [getWaiversCalled, setGetWaiversCalled] = useState(false);
  const [selectedWaiver, setSelectedWaiver] = useState({});
  const [readCommentModal, setReadCommentModal] = useState(false);
  const [waiverDetailsModal, setWaiverDetailsModal] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [queryParams, setQueryParams] = useState({
    status: 'Approve,Disapprove',
    page: initialPageNumber,
  });

  const readCommentToggle = () => setReadCommentModal(!readCommentModal);
  const waiverDetailsToggle = () => setWaiverDetailsModal(!waiverDetailsModal);

  useEffect(() => {
    document.title = 'Waiver Statuses';
  }, []);

  const getRequests = async (params) => {
    setGetWaiversStatus({
      statusType: 'loading',
    });

    const response = await fetchWaivers(params);
    const responseError = handleResponseError(response);

    if (responseError) {
      setGetWaiversStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setGetWaiversStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setWaivers(response.data.data);

    const { total, pageSize, currentPage } = response.data.pagination;

    const firstNumber = currentPage * pageSize - pageSize + 1;
    const lastNumber = currentPage * pageSize;

    setLastPage(Math.ceil(total / pageSize));
    setPaginationMeta({
      firstNumber,
      lastNumber,
      total,
      pageSize,
      currentPage,
    });

    setLastPage(Math.ceil(total / pageSize));
    setGetWaiversCalled(true);
    setGetWaiversStatus({});
  };

  useEffect(() => {
    getRequests(queryParams);
  }, [queryParams]);

  const readComment = (waiver) => {
    setSelectedWaiver(waiver);
    readCommentToggle();
  };

  const viewWaiverDetails = (waiver) => {
    setSelectedWaiver(waiver);
    waiverDetailsToggle();
  };

  const goToPage = (e) => {
    const { value } = e.target;
    setPageNumber(parseInt(value));

    setQueryParams((prevState) => ({
      ...prevState,
      page: parseInt(value),
    }));

    if (value > 1) {
      history.push(`${match.url}?page=${value}`);
    } else {
      history.push(`${match.url}`);
    }
  };

  return (
    <Container fluid tag="main">
      <Row>
        <Col>
          <Card className="border-0 shadow-sm p-5 snow-bg">
            <Row className="mb-3">
              <Col>
                <section className="d-flex">
                  <FilterWaivers
                    title="Filter"
                    filterState={setQueryParams}
                    setPageNumber={setPageNumber}
                    pageUrl={match.url}
                  />
                  <ExportWaivers
                    data={waivers}
                    type="Waiver Statuses"
                    className="ml-auto"
                  />
                </section>
              </Col>
            </Row>

            {getWaiversStatus && getWaiversStatus.statusType === 'loading' ? (
              <Loader />
            ) : (
              ''
            )}

            {getWaiversStatus && getWaiversStatus.statusType === 'error' ? (
              <Alert
                color="danger"
                children={getWaiversStatus.message}
                className="mb-4"
              />
            ) : (
              ''
            )}

            {getWaiversCalled && waivers.length !== 0 ? (
              <Fragment>
                <Table>
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>Region</th>
                      <th>Depot</th>
                      <th>Waiver Type</th>
                      <th>Product Name</th>
                      <th>Raised By</th>
                      <th className="text-center">Status</th>
                      <th>Attended By</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {waivers.map((waiver, index) => (
                      <tr key={waiver.id}>
                        <td onClick={() => viewWaiverDetails(waiver)}>
                          {typeof waiver.sku === 'string' ? (
                            waiver.sku
                          ) : waiver.sku.length > 1 ? (
                            <Fragment>
                              {waiver.sku[0].sku_id}
                              <span className="and-more">
                                and {waiver.sku.length - 1} more
                              </span>
                            </Fragment>
                          ) : (
                            waiver.sku[0]?.sku_id
                          )}
                        </td>
                        <td onClick={() => viewWaiverDetails(waiver)}>
                          {typeof waiver.region === 'string' ? (
                            waiver.region
                          ) : waiver.region.length > 1 ? (
                            <Fragment>
                              {waiver.region[0].name}
                              <span className="and-more">
                                and {waiver.region.length - 1} more
                              </span>
                            </Fragment>
                          ) : (
                            waiver.region[0].name
                          )}
                        </td>
                        <td onClick={() => viewWaiverDetails(waiver)}>
                          {typeof waiver.depot === 'string' ? (
                            waiver.depot
                          ) : waiver.depot.length > 1 ? (
                            <Fragment>
                              {waiver.depot[0].name}
                              <span className="and-more">
                                and {waiver.depot.length - 1} more
                              </span>
                            </Fragment>
                          ) : (
                            waiver.depot[0].name
                          )}
                        </td>
                        <td onClick={() => viewWaiverDetails(waiver)}>
                          {waiver.waiver_type}
                        </td>
                        <td onClick={() => viewWaiverDetails(waiver)}>
                          {typeof waiver.sku === 'string' ? (
                            waiver.sku
                          ) : waiver.sku.length > 1 ? (
                            <Fragment>
                              {waiver.sku[0].product_name}
                              <span className="and-more">
                                and {waiver.sku.length - 1} more
                              </span>
                            </Fragment>
                          ) : (
                            waiver.sku[0]?.product_name
                          )}
                        </td>
                        <td onClick={() => viewWaiverDetails(waiver)}>
                          {titleCase(waiver.raised_by)}
                          <span className="and-more">
                            {formatDate(waiver.raised_at)}
                          </span>
                        </td>
                        <td onClick={() => viewWaiverDetails(waiver)}>
                          <Badge>{waiver.status.toLowerCase()}</Badge>
                        </td>
                        <td onClick={() => viewWaiverDetails(waiver)}>
                          {waiver.attended_to_by}
                          <span className="and-more">
                            {formatDate(waiver.attended_to_at)}
                          </span>
                        </td>
                        <td>
                          {waiver.status !== 'Approve' || waiver.comment ? (
                            <UncontrolledDropdown>
                              <DropdownToggle color="link" className="px-1">
                                <VerticalDots />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => readComment(waiver)}
                                >
                                  <Comment className="mr-2" />
                                  Read Comment
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <section className="d-flex align-items-center mt-5">
                  <div>
                    {`${paginationMeta.firstNumber} to 
                    ${
                      paginationMeta.currentPage === lastPage
                        ? paginationMeta.total
                        : paginationMeta.lastNumber
                    }
                    of ${paginationMeta.total} records`}
                  </div>

                  {lastPage > 1 ? (
                    <Pagination
                      currentPage={pageNumber}
                      lastPage={lastPage}
                      onClick={goToPage}
                    />
                  ) : (
                    ''
                  )}
                </section>
              </Fragment>
            ) : (
              ''
            )}

            {getWaiversCalled && waivers.length === 0 ? <EmptyWaiver /> : ''}
          </Card>
        </Col>
      </Row>

      <ReadComment
        isOpen={readCommentModal}
        toggle={readCommentToggle}
        waiver={selectedWaiver}
      />

      <WaiverDetails
        isOpen={waiverDetailsModal}
        toggle={waiverDetailsToggle}
        waiver={selectedWaiver}
      />
    </Container>
  );
};

export default WaiverStatuses;
