import React from 'react';
import { Badge as BootBadge } from 'reactstrap';
import './_badge.scss';

const Badge = ({ children }) => (
  <BootBadge className="gbnl-badge" color={children}>
    {children}
  </BootBadge>
);

export default Badge;
