import * as React from 'react';

const VerticalDots = (props) => {
  return (
    <svg
      width={4}
      height={18}
      viewBox="0 0 4 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.3} fill="#562FE0">
        <path d="M0 2a2 2 0 104 0 2 2 0 00-4 0zM0 9a2 2 0 104 0 2 2 0 00-4 0zM0 16a2 2 0 104 0 2 2 0 00-4 0z" />
      </g>
    </svg>
  );
};

export default VerticalDots;
