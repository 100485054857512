import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';
import { Alert, Form } from 'reactstrap';
import { resetPassword } from '../../api/auth';
import { AuthLayout, Button, Input, Loader } from '../../components';
import { ERROR, SUCCESS } from '../../config/constants';
import { handleResponseError } from '../../lib/utils';
import './_resetpassword.scss';

const ResetPassword = () => {
  const [status, setStatus] = useState({});
  const [formState, { email }] = useFormState();
  const formFields = {
    email: 'email',
  };

  const resetUserPassword = async (e) => {
    e.preventDefault();

    setStatus({
      statusType: 'loading',
    });

    const response = await resetPassword(
      formState.values.email,
    );
    const responseError = handleResponseError(response);
    setStatus({});

    if (responseError) {
      setStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setStatus({
      statusType: 'success',
      message: SUCCESS.PASSWORD_RESET,
    });
  };

  return (
    <AuthLayout title={'Reset Password'}>
      <Form onSubmit={resetUserPassword}>
        {status && status.statusType === 'loading' ? (
          <Loader />
        ) : (
          ''
        )}
        {status && status.statusType === 'error' ? (
          <Alert color="danger" children={status.message} />
        ) : (
          ''
        )}
        {status && status.statusType === 'success' ? (
          <Alert color="success" children={status.message} />
        ) : (
          ''
        )}
        <Input
          label="Your Email Address"
          required
          {...email(formFields.email)}
        />
        <Button type="submit" block loading={status.statusType === 'loading'}>
          Send Password Link
        </Button>
      </Form>
      <p className="mt-3 mb-0 text-center small">
        <Link to="login">Remember Password? Login</Link>
      </p>
    </AuthLayout>
  );
};

export default ResetPassword;
