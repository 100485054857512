import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './_readcomment.scss';

const ReadComment = ({ isOpen, toggle, waiver }) => {
  return (
    <Modal isOpen={isOpen} returnFocusAfterClose={false} toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        className="p-4 d-flex justify-content-center"
        close={<span></span>}
        tag="h3"
      >Comment</ModalHeader>
      <ModalBody className="p-4">{waiver.comment}</ModalBody>
    </Modal>
  );
};

ReadComment.propTypes = {
  isOpen: PropTypes.bool,
	toggle: PropTypes.func,
	comment: PropTypes.string
};

ReadComment.defaultProps = {
	comment: 'Hello World'
};

export default ReadComment;
