import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Alert, Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, Loader } from '..';
import { createWaiver } from '../../api/waiver';
import { ERROR, SUCCESS } from '../../config/constants';
import { handleResponseError } from '../../lib/utils';
import {
  SelectDepot,
  SelectRegion,
  SelectSku,
  SelectWaiverType
} from './sub-components';
import './_raisewaiver.scss';

const RaiseWaiver = ({ isOpen, toggle, updateList }) => {
  const [raiseWaiverStatus, setRaiseWaiverStatus] = useState({});
  const [createButtonActive, setCreateButtonActive] = useState(false);
  const [allowedValues, setAllowedValues] = useState({});
  const [formData, setFormData] = useState({
    waiver_type_id: '',
    region: [],
    depot: [],
    sku: [],
  });

  useEffect(() => {
    const { depot, region, sku, waiver_type_id } = formData;

    if (
      depot.length !== 0 &&
      region.length !== 0 &&
      sku.length !== 0 &&
      waiver_type_id.length !== 0
    ) {
      setCreateButtonActive(true);
    } else {
      setCreateButtonActive(false);
    }
  }, [formData]);

  useEffect(() => {
    if (isOpen) {
      document.title = 'Raise Waiver';
    }
    return () => {
      document.title = 'Request List';
    };
  }, [isOpen]);

  const raiseWaiver = async (e) => {
    e.preventDefault();

    setRaiseWaiverStatus({
      statusType: 'loading',
    });

    const response = await createWaiver(formData);
    const responseError = handleResponseError(response);
    setRaiseWaiverStatus({});

    if (responseError) {
      setRaiseWaiverStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setRaiseWaiverStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setRaiseWaiverStatus({
      statusType: 'success',
      message: SUCCESS.WAIVER_RAISED,
    });

    setFormData({
      waiver_type_id: '',
      region: [],
      depot: [],
      sku: [],
    });

    setTimeout(() => {
      updateList();
      toggle();
      setRaiseWaiverStatus({});
    }, 1500);
  };

  return (
    <Modal isOpen={isOpen} returnFocusAfterClose={false} toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        className="p-4 d-flex justify-content-center"
        close={<span></span>}
        tag="h3"
      >
        Raise Waiver
      </ModalHeader>
      <ModalBody className="p-4">
        <Form onSubmit={raiseWaiver}>
          {raiseWaiverStatus && raiseWaiverStatus.statusType === 'loading' ? (
            <Loader />
          ) : (
            ''
          )}
          {raiseWaiverStatus && raiseWaiverStatus.statusType === 'error' ? (
            <Alert color="danger" children={raiseWaiverStatus.message} />
          ) : (
            ''
          )}
          {raiseWaiverStatus && raiseWaiverStatus.statusType === 'success' ? (
            <Alert color="success" children={raiseWaiverStatus.message} />
          ) : (
            ''
          )}

          <SelectWaiverType
            formData={formData}
            setFormData={setFormData}
            setAllowedValues={setAllowedValues}
          />

          <SelectRegion
            formData={formData}
            setFormData={setFormData}
            setAllowedValues={setAllowedValues}
            allowedValues={allowedValues}
          />

          <SelectDepot
            formData={formData}
            setFormData={setFormData}
            setAllowedValues={setAllowedValues}
            allowedValues={allowedValues}
          />

          <SelectSku
            formData={formData}
            setFormData={setFormData}
            setAllowedValues={setAllowedValues}
            allowedValues={allowedValues}
          />

          <Button
            type="submit"
            block
            disabled={!createButtonActive}
            onClick={raiseWaiver}
            loading={raiseWaiverStatus.statusType === 'loading'}
          >
            Raise Waiver
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

RaiseWaiver.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  updateList: PropTypes.func,
};

export default RaiseWaiver;
