import React from 'react';
import { Spinner } from 'reactstrap';
import './_loader.scss';

const Loader = ({absolute}) => {
  return (
    <section className={`loader ${absolute ? 'position-absolute' : 'position-fixed'}`}>
      <Spinner color="primary" style={{ width: '5rem', height: '5rem' }} />
    </section>
  );
};

export default Loader;
