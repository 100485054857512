import requestClient from '../lib/requestClient';
import { handleApiErrors } from '../lib/utils';

export const login = async (email, password) => {
  return await requestClient()
    .post('/auth/login', {
      email,
      password,
    })
    .catch((error) => {
      return handleApiErrors(error);
    });
};

export const resetPassword = async (email) => {
  return await requestClient()
    .post('/auth/passwordreset', {
      email,
    })
    .catch((error) => {
      return handleApiErrors(error);
    });
};

export const setNewPassword = async (password, token) => {
  return await requestClient()
    .patch(
      '/auth/passwordresetverify',
      {
        password,
      },
      {
        headers: {
          Authorization: token,
        },
      },
    )
    .catch((error) => {
      return handleApiErrors(error);
    });
};
