import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { FormGroup } from 'reactstrap';
import './_datetimeinput.scss';

const DateTimeInput = ({ label, ...restProps }) => {
  return (
    <FormGroup className="gbnl-input">
      <label>
        <span>{label}</span>
        <Datetime
          {...restProps}
          dateFormat={'YYYY-MM-DD'}
          timeFormat={false}
          closeOnSelect={'true'}
        />
      </label>
    </FormGroup>
  );
};

DateTimeInput.defaultProps = {};

DateTimeInput.propTypes = {};

export default DateTimeInput;
