import requestClient from '../lib/requestClient';
import { handleApiErrors } from '../lib/utils';

export const fetchWaiverTypes = async () => {
  return await requestClient()
    .get('/waivers/types')
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const fetchDepotSkus = async (depotId) => {
  return await requestClient()
    .get(`/depots/[${depotId}]/sku`)
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const fetchAllSkus = async () => {
  return await requestClient()
    .get('/waivers/skus')
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const fetchAllRegion = async () => {
  return await requestClient()
    .get('/regions')
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const fetchRegionByCode = async (regionCode) => {
  return await requestClient()
    .get(`/regions/${regionCode}`)
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const fetchRegionDepot = async (regionId) => {
  return await requestClient()
    .get(`/regions/${regionId}/depots`)
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const fetchWaivers = async (params) => {
  let query = '';
  const urlParams = [];
  let {
    limit = 20,
    status,
    date,
    startDate,
    endDate,
    region,
    depot,
    waiver_type_id,
    page,
  } = params || {};

  const queryParams = {
    limit,
    status,
    date,
    startDate,
    endDate,
    region,
    depot,
    waiver_type_id,
    page,
  };

  for (let key in queryParams) {
    const value = queryParams[key];
    value && urlParams.push(`${key}=${value}`);
  }

  if (urlParams.length > 0) {
    query = `?${urlParams.join('&')}`;
  }

  return await requestClient()
    .get(`/waivers${query}`)
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const createWaiver = async (payload) => {
  let { waiver_type_id, region, depot, sku } = payload;

  return await requestClient()
    .post(`/waivers`, {
      waiver_type_id,
      region: region,
      depot,
      sku,
    })
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const updateWaiver = async (status, comment, waiverId) => {
  return await requestClient()
    .patch(`/waivers/${waiverId}`, {
      status,
      comment,
    })
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};
