import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Alert, Card, Col, Container, Row } from 'reactstrap';
import { fetchWaivers } from '../../api/waiver';
import {
  Badge,
  Button,
  Can,
  ChangeWaiverStatus,
  EmptyWaiver,
  ExportWaivers,
  Loader,
  Pagination,
  RaiseWaiver,
  Table,
  WaiverDetails,
} from '../../components';
import { ERROR } from '../../config/constants';
import useAppContext from '../../context';
import {
  extractNumber,
  formatDate,
  handleResponseError,
  titleCase,
} from '../../lib/utils';
import './_requestlist.scss';

const RequestList = ({ match }) => {
  const location = useLocation();
  const history = useHistory();
  const { loggedInUser } = useAppContext();

  const initialPageNumber = location.search
    ? extractNumber(location.search)
    : 1;

  const [paginationMeta, setPaginationMeta] = useState({});
  const [getRequestsStatus, setGetRequestsStatus] = useState({});
  const [pendingRequests, setPendingRequests] = useState([]);
  const [getWaiversCalled, setGetWaiversCalled] = useState(false);
  const [selectedWaiver, setSelectedWaiver] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [raiseWaiverModal, setRaiseWaiverModal] = useState(false);
  const [waiverDetailsModal, setWaiverDetailsModal] = useState(false);
  const [changeWaiverStatusModal, setChangeWaiverStatusModal] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [queryParams, setQueryParams] = useState({
    page: initialPageNumber,
  });

  const raiseWaiverToggle = () => setRaiseWaiverModal(!raiseWaiverModal);
  const waiverDetailsToggle = () => setWaiverDetailsModal(!waiverDetailsModal);
  const changeWaiverStatusToggle = () =>
    setChangeWaiverStatusModal(!changeWaiverStatusModal);

  useEffect(() => {
    document.title = 'Request List';
  }, []);

  const getPendingRequests = useCallback(async (params) => {
    setGetWaiversCalled(false);

    setGetRequestsStatus({
      statusType: 'loading',
    });

    const response = await fetchWaivers(params);
    const responseError = handleResponseError(response);

    if (responseError) {
      setGetRequestsStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setGetRequestsStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setPendingRequests(response.data.data);

    const { total, pageSize, currentPage } = response.data.pagination;

    const firstNumber = currentPage * pageSize - pageSize + 1;
    const lastNumber = currentPage * pageSize;

    setLastPage(Math.ceil(total / pageSize));
    setPaginationMeta({
      firstNumber,
      lastNumber,
      total,
      pageSize,
      currentPage,
    });
    setGetWaiversCalled(true);
    setGetRequestsStatus({});
  }, []);

  const updateStatus = (status, waiver) => {
    setSelectedStatus(status);
    setSelectedWaiver(waiver);
    changeWaiverStatusToggle();
  };

  useEffect(() => {
    getPendingRequests(queryParams);
  }, [getPendingRequests, queryParams]);

  const viewWaiver = (waiver) => {
    setSelectedWaiver(waiver);
    waiverDetailsToggle();
  };

  const goToPage = async (e) => {
    const { value } = e.target;
    setPageNumber(parseInt(value));

    setQueryParams((prevState) => ({
      ...prevState,
      page: parseInt(value),
    }));

    if (value > 1) {
      history.push(`${match.url}?page=${value}`);
    } else {
      history.push(`${match.url}`);
    }
  };

  return (
    <Fragment>
      <Container fluid tag="main">
        <Row>
          <Col>
            <Card className="border-0 shadow-sm p-5 snow-bg">
              <Row className="mb-3">
                <Col>
                  <section className="d-flex">
                    <div className="ml-auto">
                      <Can
                        perform="RAISE_WAIVER"
                        yes={() => (
                          <Button onClick={raiseWaiverToggle}>
                            Raise Waiver
                          </Button>
                        )}
                      />

                      <ExportWaivers
                        type="Request List"
                        data={pendingRequests}
                        outline={loggedInUser.companyId === 1}
                        className="ml-5"
                      />
                    </div>
                  </section>
                </Col>
              </Row>

              {getRequestsStatus &&
              getRequestsStatus.statusType === 'loading' ? (
                <Loader />
              ) : (
                ''
              )}
              {getRequestsStatus && getRequestsStatus.statusType === 'error' ? (
                <Alert color="danger" children={getRequestsStatus.message} />
              ) : (
                ''
              )}

              {getWaiversCalled && pendingRequests.length !== 0 ? (
                <Fragment>
                  <Table borderless>
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Region</th>
                        <th>Depot</th>
                        <th>Waiver Type</th>
                        <th>Product Name</th>
                        <th>Raised By</th>
                        <th className="text-center">Status</th>

                        <Can
                          perform="UPDATE_WAIVER_STATUS"
                          yes={() => <th></th>}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {pendingRequests.map((waiver) => (
                        <tr key={waiver.id}>
                          <td onClick={() => viewWaiver(waiver)}>
                            {typeof waiver.sku === 'string' ? (
                              waiver.sku
                            ) : waiver.sku.length > 1 ? (
                              <Fragment>
                                {waiver.sku[0].sku_id}
                                <span className="and-more">
                                  and {waiver.sku.length - 1} more
                                </span>
                              </Fragment>
                            ) : (
                              waiver.sku[0]?.sku_id
                            )}
                          </td>
                          <td onClick={() => viewWaiver(waiver)}>
                            {typeof waiver.region === 'string' ? (
                              waiver.region
                            ) : waiver.region.length > 1 ? (
                              <Fragment>
                                {waiver.region[0].name}
                                <span className="and-more">
                                  and {waiver.region.length - 1} more
                                </span>
                              </Fragment>
                            ) : (
                              waiver.region[0].name
                            )}
                          </td>
                          <td onClick={() => viewWaiver(waiver)}>
                            {typeof waiver.depot === 'string' ? (
                              waiver.depot
                            ) : waiver.depot.length > 1 ? (
                              <Fragment>
                                {waiver.depot[0].name}
                                <span className="and-more">
                                  and {waiver.depot.length - 1} more
                                </span>
                              </Fragment>
                            ) : (
                              waiver.depot[0].name
                            )}
                          </td>
                          <td onClick={() => viewWaiver(waiver)}>
                            {waiver.waiver_type}
                          </td>
                          <td onClick={() => viewWaiver(waiver)}>
                            {typeof waiver.sku === 'string' ? (
                              waiver.sku
                            ) : waiver.sku.length > 1 ? (
                              <Fragment>
                                {waiver.sku[0].product_name}
                                <span className="and-more">
                                  and {waiver.sku.length - 1} more
                                </span>
                              </Fragment>
                            ) : (
                              waiver.sku[0]?.product_name
                            )}
                          </td>
                          <td onClick={() => viewWaiver(waiver)}>
                            {titleCase(waiver.raised_by)}
                            <span className="and-more">
                              {formatDate(waiver.raised_at)}
                            </span>
                          </td>
                          <td onClick={() => viewWaiver(waiver)}>
                            <Badge>{waiver.status.toLowerCase()}</Badge>
                          </td>

                          <Can
                            perform="UPDATE_WAIVER_STATUS"
                            yes={() => (
                              <td className="no-wrap">
                                <Button
                                  size="mini"
                                  onClick={() =>
                                    updateStatus('Approve', waiver)
                                  }
                                >
                                  Approve
                                </Button>
                                <Button
                                  size="mini"
                                  onClick={() =>
                                    updateStatus('Disapprove', waiver)
                                  }
                                  outline
                                  className="ml-2"
                                >
                                  Disapprove
                                </Button>
                              </td>
                            )}
                          />
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <section className="d-flex align-items-center mt-5">
                    <div>
                    {`${paginationMeta.firstNumber} to 
                    ${
                      paginationMeta.currentPage === lastPage
                        ? paginationMeta.total
                        : paginationMeta.lastNumber
                    }
                    of ${paginationMeta.total} records`}
                    </div>

                    {lastPage > 1 ? (
                      <Pagination
                        currentPage={pageNumber}
                        lastPage={lastPage}
                        onClick={goToPage}
                      />
                    ) : (
                      ''
                    )}
                  </section>
                </Fragment>
              ) : (
                ''
              )}

              {getWaiversCalled && pendingRequests.length === 0 ? (
                <EmptyWaiver />
              ) : (
                ''
              )}
            </Card>
          </Col>
        </Row>
      </Container>

      <Can
        perform="RAISE_WAIVER"
        yes={() => (
          <RaiseWaiver
            isOpen={raiseWaiverModal}
            toggle={raiseWaiverToggle}
            updateList={getPendingRequests}
          />
        )}
      />

      <Can
        perform="UPDATE_WAIVER_STATUS"
        yes={() => (
          <ChangeWaiverStatus
            isOpen={changeWaiverStatusModal}
            toggle={changeWaiverStatusToggle}
            updateList={getPendingRequests}
            waiver={selectedWaiver}
            status={selectedStatus}
          />
        )}
      />

      <WaiverDetails
        waiver={selectedWaiver}
        isOpen={waiverDetailsModal}
        toggle={waiverDetailsToggle}
        updateStatus={updateStatus}
      />
    </Fragment>
  );
};

export default RequestList;
