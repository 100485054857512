import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import {
  Alert,
  Card,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  changeStatus,
  fetchCompanies,
  fetchRoles,
  fetchUsers,
  inviteNewUser,
} from '../../api/user';
import { Button, Can, Input, Loader, Select, Table } from '../../components';
import { ERROR } from '../../config/constants';
import useAppContext from '../../context';
import { handleResponseError, sentenceCase } from '../../lib/utils';
import './_usersettings.scss';

const UserSettings = () => {
  const { loggedInUser } = useAppContext();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    document.title = 'User Settings';
  }, []);

  const [status, setStatus] = useState({});
  const [inviteStatus, setInviteStatus] = useState({});
  const [usersStatus, setUsersStatus] = useState({});

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [formState, { email, select }] = useFormState();
  const formFields = {
    email: 'email',
    company: 'company',
    role: 'role',
  };

  const getRoles = async () => {
    const response = await fetchRoles();
    const responseError = handleResponseError(response);

    if (responseError) {
      setInviteStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setInviteStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setRoles(response.data.data);
    setInviteStatus({});
  };

  const getCompanies = async () => {
    const response = await fetchCompanies();
    const responseError = handleResponseError(response);

    if (responseError) {
      setInviteStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setInviteStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setCompanies(response.data.data);
    setInviteStatus({});
  };

  const getUsers = async () => {
    setUsersStatus({
      statusType: 'loading',
    });

    const response = await fetchUsers();
    const responseError = handleResponseError(response);
    setUsersStatus({});

    if (responseError) {
      setUsersStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setUsersStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setUsers(response.data.data);
    setUsersStatus({});
  };

  const changeUserStatus = async (e, userId, newStatus) => {
    e.preventDefault();

    setStatus({
      statusType: 'loading',
    });

    const response = await changeStatus(userId, newStatus);
    const responseError = handleResponseError(response);
    setStatus({});

    if (responseError) {
      setStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setStatus({
      statusType: 'success',
      message: response.data.message,
    });

    setTimeout(() => {
      setStatus({});
    }, 2500);

    getUsers();

    e.target.blur();
  };

  const startUserInvitation = async () => {
    setInviteStatus({
      statusType: 'loading',
    });

    toggle();
    await getRoles();
    await getCompanies();

    setInviteStatus({});
  };

  const inviteUser = async (e) => {
    e.preventDefault();

    setInviteStatus({
      statusType: 'loading',
    });

    const response = await inviteNewUser(
      formState.values.email,
      formState.values.company,
      formState.values.role,
    );
    const responseError = handleResponseError(response);
    setInviteStatus({});

    if (responseError) {
      setInviteStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setInviteStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setInviteStatus({
      statusType: 'success',
      message: 'An invite has been sent to the user.',
    });

    formState.clear();
    setTimeout(() => {
      toggle();
      setInviteStatus({});
      getUsers();
    }, 2500);
  };

  useEffect(() => {
    getUsers();
    return () => {
      setUsers([]);
    };
  }, []);

  return (
    <Col md={10} lg={9} xl={8}>
      <Row className="mb-3">
        <Col>
          <section className="d-flex">
            <Can
              perform="INVITE_USERS"
              yes={() => (
                <Button className="ml-auto" onClick={startUserInvitation}>
                  Invite User
                </Button>
              )}
            />
          </section>
        </Col>
      </Row>
      <Card className="border-0 shadow-sm p-5 snow-bg">
        {usersStatus && usersStatus.statusType === 'loading' ? <Loader /> : ''}
        {usersStatus && usersStatus.statusType === 'error' ? (
          <Alert
            color="danger"
            children={usersStatus.message}
            className="mb-4"
          />
        ) : (
          ''
        )}
        {status && status.statusType === 'loading' ? <Loader /> : ''}
        {status && status.statusType === 'error' ? (
          <Alert color="danger" children={status.message} className="mb-4" />
        ) : (
          ''
        )}
        {status && status.statusType === 'success' ? (
          <Alert color="success" children={status.message} className="mb-4" />
        ) : (
          ''
        )}
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Company</th>

              <Can
                perform="MANAGE_USERS"
                yes={() => <th className="text-center">Action</th>}
              />
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => (
                <tr key={user.id}>
                  <td>{`${
                    user.firstName ? sentenceCase(user.firstName) : 'Invited'
                  } ${
                    user.lastName ? sentenceCase(user.lastName) : 'User'
                  }`}</td>
                  <td>{user.email}</td>
                  <td>{sentenceCase(user.role.roleName)}</td>
                  <td>{sentenceCase(user.company.name)}</td>

                  <Can
                    perform="MANAGE_USERS"
                    yes={() => (
                      <td className="text-center">
                        <Button
                          outline
                          size="mini"
                          style={{ width: 75 }}
                          color={user.activate ? 'danger' : 'primary'}
                          disabled={user.id === loggedInUser.id}
                          onClick={(e) =>
                            changeUserStatus(e, user.id, !user.activate)
                          }
                        >
                          {user.activate ? 'Block' : 'Unblock'}
                        </Button>
                      </td>
                    )}
                  />
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>

      <Modal isOpen={modal} returnFocusAfterClose={false} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          className="p-4 d-flex justify-content-center"
          close={<span></span>}
          tag="h3"
        >
          Invite User
        </ModalHeader>
        <ModalBody className="p-4">
          <Form onSubmit={inviteUser}>
            {inviteStatus && inviteStatus.statusType === 'loading' ? (
              <Loader />
            ) : (
              ''
            )}
            {inviteStatus && inviteStatus.statusType === 'error' ? (
              <Alert color="danger" children={inviteStatus.message} />
            ) : (
              ''
            )}
            {inviteStatus && inviteStatus.statusType === 'success' ? (
              <Alert color="success" children={inviteStatus.message} />
            ) : (
              ''
            )}
            <Input
              label="Email Address"
              {...email(formFields.email)}
              required
            />

            <Select label="Company" {...select(formFields.company)} required>
              <option value="" disabled>
                Select a company
              </option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {sentenceCase(company.name)}
                </option>
              ))}
            </Select>

            <Select label="Role" {...select(formFields.role)} required>
              <option value="" disabled>
                Select a role
              </option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {sentenceCase(role.roleName)}
                </option>
              ))}
            </Select>

            <Button
              type="submit"
              block
              loading={inviteStatus.statusType === 'loading'}
            >
              Invite User
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </Col>
  );
};

export default UserSettings;
