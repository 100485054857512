const CONSTANTS = {
  ERROR: {
    REQUEST_ERROR:
      'There was an error processing your request. Please, try again later.',
    RESPONSE_ERROR:
      'There was an error processing your request. Please, try again later.',
    NOT_ACTIVATED:
      'Sorry, you are not allowed to login. Please, contact admin.',
    CONFIRM_PASSWORD: 'Passwords do not match. Please check your password.',
    CURRENT_NEW_PASSWORD: 'Current Password and New Password cannot be the same. Please check your password.',
  },
  SUCCESS: {
    LOGGED_IN:
      'Login Successful. Please, wait while we redirect you to the dashboard',
    PASSWORD_RESET:
      'An reset password link has been sent to your email. Please click on the link to continue',
    PASSWORD_CHANGED_SUCCESSFULLY:
      'Your account password has been changed successfully. Please, login with your new password',
    PROFILE_UPDATED: 'Your profile has been updated',
    WAIVER_RAISED: 'Waiver raised successfully',
    WAIVER_UPDATED: 'Waiver status successfully updated',
  },
};

module.exports = CONSTANTS;
