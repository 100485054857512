import React, { Fragment, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import { Card, CardTitle, Col, Container, Row } from 'reactstrap';
import useAppContext from '../../context';
import './_authlayout.scss';

const AuthLayout = ({ title, children }) => {
  const location = useLocation();
  const { loggedInUser } = useAppContext();
  const { referer } = location.state || '/';

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Fragment>
      {loggedInUser ? (
        <Redirect
          to={{
            pathname: referer,
          }}
        />
      ) : (
        <section className="authlayout d-flex align-items-center py-4">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <Card className="border-0 shadow-lg">
                  {title ? (
                    <CardTitle tag="h3" className="text-center mb-4">
                      {title}
                    </CardTitle>
                  ) : (
                    ''
                  )}
                  {children}
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </Fragment>
  );
};

export default AuthLayout;
