import React, { useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { Alert, Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, Loader, TextArea } from '..';
import { updateWaiver } from '../../api/waiver';
import { ERROR, SUCCESS } from '../../config/constants';
import { handleResponseError } from '../../lib/utils';
import './_changewaiverstatus.scss';

const ChangeWaiverStatus = ({ isOpen, toggle, waiver, status, updateList }) => {
  const [changeWaiverStatus, setChangeWaiverStatus] = useState({});

  const [formState, { textarea }] = useFormState();
  const formFields = {
    comment: 'comment',
  };
  const updateWaiverStatus = async (e) => {
    e.preventDefault();

    setChangeWaiverStatus({
      statusType: 'loading',
    });

    const response = await updateWaiver(
      status,
      formState.values.comment,
      waiver.id,
    );
    const responseError = handleResponseError(response);
    setChangeWaiverStatus({});

    if (responseError) {
      setChangeWaiverStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setChangeWaiverStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setChangeWaiverStatus({
      statusType: 'success',
      message: SUCCESS.WAIVER_UPDATED,
    });

    formState.reset();

    setTimeout(() => {
      updateList();
      toggle();
      setChangeWaiverStatus({})
    }, 1500);

  };
  return (
    <Modal isOpen={isOpen} returnFocusAfterClose={false} toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        className="p-4 d-flex justify-content-center"
        close={<span></span>}
        tag="h3"
      >
        {status} Waiver
      </ModalHeader>
      <ModalBody className="p-4">
        {changeWaiverStatus && changeWaiverStatus.statusType === 'loading' ? (
          <Loader />
        ) : (
          ''
        )}
        {changeWaiverStatus && changeWaiverStatus.statusType === 'error' ? (
          <Alert color="danger" children={changeWaiverStatus.message} />
        ) : (
          ''
        )}
        {changeWaiverStatus && changeWaiverStatus.statusType === 'success' ? (
          <Alert color="success" children={changeWaiverStatus.message} />
        ) : (
          ''
        )}

        <p className="text-center">
          You are about to {status} the waiver id: {waiver.id}
        </p>
        <Form onSubmit={updateWaiverStatus}>
          <TextArea
            row={5}
            placeholder="Write a comment"
            required={status === 'Disapprove'}
            helpText={status === 'Approve' ? 'Optional' : ''}
            {...textarea(formFields.comment)}
          />
          <div className="d-flex">
            <Button
              type="submit"
              loading={changeWaiverStatus.statusType === 'loading'}
            >
              {status}
            </Button>
            <Button
              outline
              type="button"
              className="ml-auto"
              onClick={toggle}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ChangeWaiverStatus;
