import React from 'react';
import { Table as BootTable } from 'reactstrap';
import './_table.scss';

const Table = ({ children, ...restProps }) => (
  <BootTable responsive borderless className="gbnl-table" {...restProps}>
    {children}
  </BootTable>
);

Table.defaultProps = {};

Table.propTypes = {};

export default Table;
