import PropTypes from 'prop-types';
import React from 'react';
import {
  Pagination as Pager,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import './_pagination.scss';

const Pagination = ({ currentPage, lastPage, onClick, ...restProps }) => {
  const paginate = () => {
    const delta = 2;
    const range = [];

    for (
      let i = Math.max(2, currentPage - delta);
      i <= Math.min(lastPage - 1, currentPage + delta);
      i += 1
    ) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      range.unshift('...');
    }

    if (currentPage + delta < lastPage - 1) {
      range.push('...');
    }

    range.unshift(1);
    if (lastPage !== 1) range.push(lastPage);

    return range.map((i, index) => {
      return !isNaN(i) ? (
        <PaginationItem key={index} active={currentPage === i}>
          <PaginationLink value={i} onClick={onClick} children={i} />
        </PaginationItem>
      ) : (
        <PaginationItem disabled key={index}>
          <PaginationLink children={i} />
        </PaginationItem>
      );
    });
  }; /* 

    return range.map((i, index) => {
      return !isNaN(i) ? (
        <PaginationItem key={index} active={currentPage === i}>
          <PaginationLink value={i} onClick={onClick} children={i} />
        </PaginationItem>
      ) : (
        <PaginationItem disabled key={index}>
          <PaginationLink children={i} />
        </PaginationItem>
      );
    });
  // }; */

  return (
    <section className="pagination-wrapper">
      <Pager {...restProps}>{paginate(currentPage, lastPage, onClick)}</Pager>
    </section>
  );
};

Pagination.defaultProps = {
  currentPage: 0,
  lastPage: 0,
  onClick: null,
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Pagination;
