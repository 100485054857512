import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useFormState } from 'react-use-form-state';
import { Alert, Form } from 'reactstrap';
import { setNewPassword } from '../../api/auth';
import { AuthLayout, Button, Input, Loader } from '../../components';
import { ERROR, SUCCESS } from '../../config/constants';
import { compareValues, handleResponseError } from '../../lib/utils';
import './_newpassword.scss';
const NewPassword = () => {
  const history = useHistory();
  const [status, setStatus] = useState({});
  const [formState, { password }] = useFormState();
  const formFields = {
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword',
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let token = useQuery().get('token');

  const createNewPassword = async (e) => {
    e.preventDefault();

    const matchingPasswords = compareValues(
      formState.values.confirmPassword,
      formState.values.newPassword,
    );

    if (!matchingPasswords) {
      setStatus({
        statusType: 'error',
        message: ERROR.CONFIRM_PASSWORD,
      });
      return;
    }

    setStatus({
      statusType: 'loading',
    });

    const response = await setNewPassword(
      formState.values.newPassword,
      token
    );
    const responseError = handleResponseError(response);
    setStatus({});

    if (responseError) {
      setStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setStatus({
      statusType: 'success',
      message: SUCCESS.PASSWORD_CHANGED_SUCCESSFULLY,
    });

    setTimeout(() => {
      history.push('/login')
    }, 1500);
  };

  return (
    <AuthLayout title={'Set New Password'}>
      <Form onSubmit={createNewPassword}>
        {status && status.statusType === 'loading' ? (
          <Loader absolute />
        ) : (
          ''
        )}
        {status && status.statusType === 'error' ? (
          <Alert color="danger" children={status.message} />
        ) : (
          ''
        )}
        {status && status.statusType === 'success' ? (
          <Alert color="success" children={status.message} />
        ) : (
          ''
        )}
        <Input
          label="New Password"
          required
          {...password(formFields.newPassword)}
        />

        <Input
          label="Confirm New Password"
          {...password({
            name: formFields.confirmPassword,
            validate: (value) =>
              compareValues(value, formState.values.newPassword),
          })}
          errorMessage={
            formState.touched.confirmPassword &&
            !formState.validity.confirmPassword
              ? ERROR.CONFIRM_PASSWORD
              : ''
          }
          error={true}
          required
        />
        <Button type="submit" block loading={status.statusType === 'loading'}>
          Change Password
        </Button>
      </Form>
    </AuthLayout>
  );
};

export default NewPassword;
