import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useFormState } from 'react-use-form-state';
import { Form } from 'reactstrap';
import { Button, Loader, Select } from '..';
import {
  fetchAllRegion,
  fetchRegionDepot,
  fetchWaiverTypes
} from '../../api/waiver';
import { ERROR } from '../../config/constants';
import { handleResponseError, titleCase } from '../../lib/utils';
import FilterIcon from '../icons/Filter';
import './_filter.scss';

const FilterWaivers = ({ title, filterState, setPageNumber, pageUrl }) => {
  const history = useHistory();
  const [getRegionStatus, setGetRegionStatus] = useState({});
  const [regions, setRegions] = useState([]);
  const [depots, setDepots] = useState([]);
  const [getDepotStatus, setGetDepotStatus] = useState({});
  const [getWaiverTypeStatus, setGetWaiverTypeStatus] = useState({});
  const [waiverTypes, setWaiverTypes] = useState([]);
  const initialValue = {
    status: 'Approve,Disapprove',
  };

  const [formState, { select }] = useFormState(initialValue);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const formFields = {
    status: 'status',
    waiver_type: 'waiver_type',
    region: 'region',
    depot: 'depot',
    date: 'date',
  };

  const getWaiverTypes = useCallback(async () => {
    setGetWaiverTypeStatus({
      statusType: 'loading',
    });

    const response = await fetchWaiverTypes();
    const responseError = handleResponseError(response);

    if (responseError) {
      setGetWaiverTypeStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setGetWaiverTypeStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setWaiverTypes(response.data.data);
    setGetWaiverTypeStatus({});
  }, []);

  const getRegions = useCallback(async () => {
    setGetRegionStatus({
      statusType: 'loading',
    });

    const response = await fetchAllRegion();
    const responseError = handleResponseError(response);

    if (responseError) {
      setGetRegionStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setGetRegionStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setRegions(response.data.data);
    setGetRegionStatus({});
  }, []);

  const getDepots = useCallback(async (region) => {
    setGetDepotStatus({
      statusType: 'loading',
    });

    const response = await fetchRegionDepot(region);
    const responseError = handleResponseError(response);

    if (responseError) {
      setGetDepotStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setGetDepotStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setDepots(response.data.data);

    setGetDepotStatus({});
  }, []);

  const filterWaivers = (e) => {
    e.preventDefault();
    setPageNumber(1);
    history.push(pageUrl);

    filterState((prevState) => ({
      ...prevState,
      status: formState.values.status,
      region: formState.values.region,
      depot: formState.values.depot,
      waiver_type_id: formState.values.waiver_type,
    }));

    toggle();
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setPageNumber(1);
    history.push(pageUrl);

    formState.reset();
    filterState({ status: 'Approve,Disapprove' });

    toggle();
  };

  useEffect(() => {
    if (dropdownOpen && regions.length === 0) {
      getRegions();
    }
  }, [dropdownOpen, getRegions, regions.length]);

  useEffect(() => {
    if (dropdownOpen && waiverTypes.length === 0) {
      getWaiverTypes();
    }
  }, [dropdownOpen, getWaiverTypes, waiverTypes.length]);

  useEffect(() => {
    const selectedRegion = formState.values.region
      ? JSON.parse(formState.values.region)
      : {};

    if (selectedRegion.id) {
      getDepots(selectedRegion.id);
    }
  }, [dropdownOpen, getDepots, formState.values.region]);

  return (
    <Fragment>
      <section className="filter">
        <Button onClick={toggle} color="link" className="p-0">
          <FilterIcon />
        </Button>
        {dropdownOpen ? (
          <Fragment>
            <section className="filter-form-wrapper">
              <header className="filter-form-header">{title}</header>
              {(getWaiverTypeStatus &&
                getWaiverTypeStatus.statusType === 'loading') ||
              (getRegionStatus && getRegionStatus.statusType === 'loading') ||
              (getDepotStatus && getDepotStatus.statusType === 'loading') ? (
                <Loader />
              ) : (
                ''
              )}
              <Form>
                <div className="filter-form-content">
                  <Select label="Status" {...select(formFields.status)}>
                    <option value="" disabled>
                      Status
                    </option>
                    <option value="Approve,Disapprove">All</option>
                    <option value="Approve">Approve</option>
                    <option value="Disapprove">Disapprove</option>
                  </Select>
                  <Select
                    label="Waiver Type"
                    {...select(formFields.waiver_type)}
                  >
                    <option value="">All</option>
                    {waiverTypes.map((waiver_type) => (
                      <option value={waiver_type.id} key={waiver_type.id}>
                        {waiver_type.type}
                      </option>
                    ))}
                  </Select>
                  <Select label="Date" />
                  <Select label="Region" {...select(formFields.region)}>
                    <option value="">All</option>
                    {regions.map((region) => (
                      <option
                        value={JSON.stringify({
                          id: region.id,
                          name: region.name,
                        })}
                        key={region.id}
                      >
                        {titleCase(region.name)}
                      </option>
                    ))}
                  </Select>
                  <Select label="Depot" {...select(formFields.depot)}>
                    <option value="">All</option>
                    {depots.map((depot) => (
                      <option
                        value={JSON.stringify({
                          id: depot.id,
                          name: depot.name,
                        })}
                        key={depot.id}
                      >
                        {titleCase(depot.name)}
                      </option>
                    ))}
                  </Select>
                </div>

                <div className="filter-form-footer">
                  <Button onClick={filterWaivers}>Filter</Button>
                  <Button outline onClick={resetFilter} className="ml-auto">
                    Reset
                  </Button>
                </div>
              </Form>
            </section>
            <aside className="filter-backdrop" onClick={toggle}></aside>
          </Fragment>
        ) : (
          ''
        )}
      </section>
    </Fragment>
  );
};

FilterWaivers.defaultProps = {};

FilterWaivers.propTypes = {};

export default FilterWaivers;
