import { Fragment, useCallback, useEffect, useState } from 'react';
import { Loader, MultiSelect, Select } from '../..';
import { fetchDepotSkus, fetchAllSkus } from '../../../api/waiver';
import { ERROR } from '../../../config/constants';
import { handleResponseError } from '../../../lib/utils';

const SelectSku = ({
  formData,
  setFormData,
  allowedValues,
  setAllowedValues,
}) => {
  const [getSkusStatus, setGetSkusStatus] = useState({});
  const [allSkus, setAllSkus] = useState([]);
  const [uniqueSkus, setUniqueSkus] = useState([]);
  const [selectedSku, setSelectedSku] = useState([]);

  const getSkus = useCallback(async (depot) => {
    setGetSkusStatus({
      statusType: 'loading',
    });

    let depotArray = [];
    let response;

    if (depotArray && typeof depot !== 'string' && depot.length !== 0) {
      depot.forEach((item) => depotArray.push(item));
      response = await fetchDepotSkus(depotArray);
    } else {
      response = await fetchAllSkus();
    }

    const responseError = handleResponseError(response);

    if (responseError) {
      setGetSkusStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setGetSkusStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setAllSkus(response.data.data);

    const skuArray = [];

    const uniqueSkusMap = [
      ...new Map(
        response.data.data.map((item) => [item.sku_id, item]),
      ).values(),
    ];

    uniqueSkusMap.forEach((sku) =>
      skuArray.push({
        value: sku.id,
        sku_id: sku.sku_id,
        label: `${sku.sku_id} - ${sku.name}`,
      }),
    );

    setUniqueSkus(skuArray);

    setGetSkusStatus({});
  }, []);

  const singleSkuSelection = (e) => {
    let { value } = e.target;
    setSelectedSku(value);

    setFormData((prevState) => ({
      ...prevState,
      sku: value,
    }));
  };

  const multipleSkuSelection = (selected) => {
    setSelectedSku(selected);

    const allSelectedSkus = [];

    for (let arr in allSkus) {
      for (let filter in selected) {
        if (allSkus[arr].sku_id === selected[filter].sku_id) {
          allSelectedSkus.push(allSkus[arr].id);
        }
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      sku: allSelectedSkus,
    }));
  };

  useEffect(() => {
    if (formData.depot.length !== 0 && allowedValues.sku.includes('many')) {
      getSkus(formData.depot);
    }
  }, [getSkus, formData.depot, allowedValues.sku]);

  useEffect(() => {
    setSelectedSku([]);
  }, [formData.depot]);

  return (
    <Fragment>
      {getSkusStatus && getSkusStatus.statusType === 'loading' ? (
        <Loader />
      ) : (
        ''
      )}

      {allowedValues.sku && allowedValues.sku.includes('many') ? (
        <MultiSelect
          label="SKU"
          options={uniqueSkus}
          value={selectedSku}
          onChange={multipleSkuSelection}
          isDisabled={uniqueSkus.length === 0}
          allowAll={allowedValues.sku.includes('all')}
        />
      ) : (
        ''
      )}

      {!allowedValues.sku || !allowedValues.sku.includes('many') ? (
        <Select
          label="SKU"
          value={typeof selectedSku === 'string' ? selectedSku : ''}
          onChange={singleSkuSelection}
          required
          disabled={!allowedValues.sku}
          errorMessage={
            getSkusStatus.statusType === 'error'
              ? 'There was an error loading sku.'
              : ''
          }
        >
          <option value="" disabled>
            Select SKU
          </option>
          {allowedValues.sku && allowedValues.sku.includes('all') ? (
            <option value="All">All</option>
          ) : (
            ''
          )}
          {allowedValues.sku && allowedValues.sku.includes('one')
            ? uniqueSkus.map((sku) => (
                <option value={sku.id} key={sku.id}>
                  {sku.name}
                </option>
              ))
            : ''}
        </Select>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default SelectSku;
