import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from './components';
import { Login, NewPassword, ResetPassword } from './pages';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/new-password">
          <NewPassword />
        </Route>
        <Route path="/">
          <Layout />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
