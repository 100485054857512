import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import useAppContext from '../../context';
import CaretDown from '../icons/CaretDown';
import Logo from '../icons/Logo';
import LogoutIcon from '../icons/Logout';
import Bell from '../icons/NotificationBell';
import './_header.scss';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const { loggedInUser, logout } = useAppContext();

  return (
    <header className="header main-header shadow-sm mb-5">
      <Container fluid>
        <Row>
          <Navbar light expand="md" className="w-100">
            <Link to="/request-list" className="logo">
              <Logo />
            </Link>

            <Collapse isOpen={dropdownOpen} navbar className="mt-md-0 mt-2">
              <Nav navbar className="w-100">
                <hr className="d-md-none" />
                <NavItem>
                  <NavLink to="/request-list">Request List</NavLink>
                </NavItem>

                <hr className="d-md-none" />
                <NavItem>
                  <NavLink to="/waiver-statuses">Waiver Statuses</NavLink>
                </NavItem>

                <hr className="d-md-none" />
                <NavItem className="ml-md-auto">
                  <NavLink to="/settings">Settings</NavLink>
                </NavItem>
              </Nav>
            </Collapse>

            <Nav navbar className="navbar-icons">
              <NavItem>
                <Bell />
              </NavItem>

              <UncontrolledDropdown nav inNavbar className="mt-md-0 mt-2">
                <DropdownToggle nav className="ml-md-4 p-0">
                  <div className="d-none d-md-flex align-items-center">
                    <span className="ellipsis">{`${loggedInUser.firstName} ${loggedInUser.lastName}`}</span>
                    <CaretDown className="ml-2" />
                  </div>
                </DropdownToggle>

                <DropdownMenu right className="p-2 w-100">
                  <DropdownItem onClick={logout} className="">
                    <LogoutIcon className="mr-3" /> Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavbarToggler onClick={toggle} />
            </Nav>
          </Navbar>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
