import JwtDecode from 'jwt-decode';
import React, { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header } from '..';
import useAppContext from '../../context';
import { Error404, RequestList, Settings, WaiverStatuses } from '../../pages';
import './_layout.scss';

const Layout = () => {
  const { loggedInUser, token, logout } = useAppContext();
  let logoutTimer;

  useEffect(() => {
    startTimeout();
    checkTokenExpiry();
  });

  const checkTokenExpiry = () => {
    if (token) {
      const expiryDate = JwtDecode(token).exp;
      const currentTime = new Date().getTime() / 1000;

      if (currentTime > expiryDate) {
        logout();
      }
    }
  };

  const startTimeout = () => {
    logoutTimer = setTimeout(logout, 1800 * 1000);
  };

  const resetTimeout = () => {
    if (logoutTimer) clearTimeout(logoutTimer);
    startTimeout();
  };

  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];

  for (let i in events) {
    window.addEventListener(events[i], resetTimeout);
  }

  return (
    <Fragment>
      {loggedInUser ? (
        <section className="layout">
          <Header />

          {!loggedInUser.firstName || !loggedInUser.lastName ? (
            <Fragment>
              <Route path="/">
                <Redirect
                  to={{
                    pathname: '/settings/profile',
                  }}
                />
              </Route>
            </Fragment>
          ) : (
            ''
          )}

          <Switch>
            <Route path="/" exact>
              <Redirect
                to={{
                  pathname: '/request-list',
                }}
              />
            </Route>
            <Route path="/request-list" exact component={RequestList} />
            <Route path="/waiver-statuses" component={WaiverStatuses} />
            <Route path="/settings" component={Settings} />
            <Route path="" exact component={Error404} />
          </Switch>
        </section>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { referer: window.location.pathname },
          }}
        />
      )}
    </Fragment>
  );
};

Layout.defaultProps = {};

Layout.propTypes = {};

export default Layout;
