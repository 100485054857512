import * as React from 'react';

const CaretDown = (props) => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.443 6.442L9 9.877 5.558 6.442 4.5 7.5 9 12l4.5-4.5-1.057-1.058z"
        fill="#666"
      />
    </svg>
  );
}

export default CaretDown;
