import requestClient from '../lib/requestClient';
import { handleApiErrors } from '../lib/utils';

export const updateUserProfile = async (firstName, lastName) => {
  return await requestClient()
    .patch('/user/update', {
      firstName,
      lastName,
    })
    .catch((error) => {
      return handleApiErrors(error);
    });
};

export const changeStatus = async (userId, activate) => {
  return await requestClient()
    .patch(`/admin/user/${userId}`, {
      activate,
    })
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const inviteNewUser = async (email, companyId, roleId) => {
  return await requestClient()
    .post('/admin/setup-user', {
      email,
      companyId,
      roleId,
    })
    .catch((error) => {
      return handleApiErrors(error);
    });
};

export const fetchUsers = async () => {
  return await requestClient()
    .get('/user')
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const fetchCompanies = async () => {
  return await requestClient()
    .get('/companies')
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const fetchRoles = async () => {
  return await requestClient()
    .get('/roles')
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};

export const changeUserPassword = async (currentPassword, newPassword) => {
  return await requestClient()
    .patch('/auth/changepassword', {
      currentPassword,
      newPassword,
    })
    .catch((error) => {
      return { error: handleApiErrors(error) };
    });
};
