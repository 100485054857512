import * as React from 'react';

const Filter = (props) => {
  return (
    <svg
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.66}
        y={0.66}
        width={42.68}
        height={42.68}
        rx={2.34}
        fill="#fff"
        stroke="#F2F2F2"
        strokeWidth={0.68}
      />
      <path
        d="M24.21 22.518v3.717a.354.354 0 01-.189.316l-2.736 1.411c-.228.118-.495-.053-.495-.315v-5.13l-4.712-5.94c-.183-.231-.024-.577.265-.577h12.314c.289 0 .448.346.265.576l-4.712 5.942z"
        fill="#4F4F4F"
      />
    </svg>
  );
};
export default Filter;
