import React from 'react';
import { Button } from '..';
import {
  convertObjectArrayToCsv,
  formatDate,
  titleCase,
} from '../../lib/utils';
import './_exportwaivers.scss';

const ExportWaivers = ({ data, type, ...restProps }) => {
  const processExport = async () => {
    const exportData = data.map((v) => ({
      ...v,
      raised_at: formatDate(v.raised_at),
      attended_to_at: v.attended_to_at ? formatDate(v.attended_to_at) : '',
      createdAt: formatDate(v.createdAt),
      end_date: formatDate(v.end_date),
      updatedAt: formatDate(v.updatedAt),
      raised_by: titleCase(v.raised_by),
      attended_to_by: v.attended_to_by ? titleCase(v.attended_to_by) : '',
      depot:
        v.depot && typeof v.depot !== 'string'
          ? v.depot.map((item) => titleCase(item.name)).join(', ')
          : v.depot,
      region:
        v.region && typeof v.region !== 'string'
          ? v.region.map((item) => titleCase(item.name)).join(', ')
          : v.region,
      sku:
        v.sku && typeof v.sku !== 'string'
          ? v.sku.map((item) => titleCase(item.sku_id)).join(', ')
          : v.sku,
      product:
        v.sku && typeof v.sku !== 'string'
          ? v.sku.map((item) => titleCase(item.product_name)).join(', ')
          : v.sku,
		}));

    convertObjectArrayToCsv(exportData, type);
  };

  return (
    <Button
      {...restProps}
      onClick={processExport}
      disabled={!data || data.length === 0}
    >
      Export as CSV
    </Button>
  );
};

export default ExportWaivers;
