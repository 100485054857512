const rules = [
  {
    id: 1,
    name: 'gbnl',
    roles: [
      {
        id: 1,
        name: 'superadmin',
        permissions: [
          'VIEW_WAIVERS',
          'RAISE_WAIVER',
          'VIEW_USERS',
          'INVITE_USERS',
          'MANAGE_USERS',
        ],
      },
      {
        id: 2,
        name: 'admin',
        permissions: ['VIEW_WAIVERS', 'VIEW_USERS'],
      },
      {
        id: 3,
        name: 'patron',
        permissions: [
          'VIEW_WAIVERS',
          'RAISE_WAIVER',
          'VIEW_USERS',
          'INVITE_USERS',
          'MANAGE_USERS',
          'UPDATE_WAIVER_STATUs',
          'VIEW_USERS',
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'bat',
    roles: [
      {
        id: 1,
        name: 'superadmin',
        permissions: ['VIEW_WAIVERS', 'UPDATE_WAIVER_STATUS'],
      },
      {
        id: 2,
        name: 'admin',
        permissions: ['VIEW_WAIVERS'],
      },
    ],
  },
];

export default rules;
