import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { changeUserPassword, updateUserProfile } from '../../api/user';
import { Button, Input, Loader } from '../../components';
import { ERROR, SUCCESS } from '../../config/constants';
import useAppContext from '../../context';
import {
  compareValues,
  handleResponseError,
  sentenceCase,
} from '../../lib/utils';
import './_profilesettings.scss';

const ProfileSettings = () => {
  const { loggedInUser, setSession } = useAppContext();

  const initialValue = {
    firstName: loggedInUser.firstName || '',
    lastName: loggedInUser.lastName || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  useEffect(() => {
    document.title = 'Profile Settings';
  }, []);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [passwordStatus, setPasswordStatus] = useState({});
  const [profileStatus, setProfileStatus] = useState({});
  const [formState, { text, password }] = useFormState(initialValue);
  const formFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    currentPassword: 'currentPassword',
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword',
  };

  const formUnChanged = () => {
    return (
      JSON.stringify(formState.values).toLowerCase() ===
      JSON.stringify(initialValue).toLowerCase()
    );
  };

  const updateProfile = async (e) => {
    e.preventDefault();

    setProfileStatus({
      statusType: 'loading',
    });

    const response = await updateUserProfile(
      formState.values.firstName,
      formState.values.lastName,
    );
    const responseError = handleResponseError(response);
    setProfileStatus({});

    if (responseError) {
      setProfileStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setProfileStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    const { user } = response.data;

    setProfileStatus({
      statusType: 'success',
      message: SUCCESS.PROFILE_UPDATED,
    });

    const currentDetails = JSON.parse(localStorage.auth);

    const { token, loggedInUser } = currentDetails;

    loggedInUser.firstName = sentenceCase(user.firstName);
    loggedInUser.lastName = sentenceCase(user.lastName);

    setSession({ token, loggedInUser });

    setTimeout(() => {
      setProfileStatus({});
    }, 3000);
  };

  const changePassword = async (e) => {
    e.preventDefault();

    const matchingPasswords = compareValues(
      formState.values.newPassword,
      formState.values.confirmPassword,
    );

    const matchingNewPassword = compareValues(
      formState.values.currentPassword,
      formState.values.newPassword,
    );

    if (matchingNewPassword) {
      setPasswordStatus({
        statusType: 'error',
        message: ERROR.CURRENT_NEW_PASSWORD,
      });
      return;
    }

    if (!matchingPasswords) {
      setPasswordStatus({
        statusType: 'error',
        message: ERROR.CONFIRM_PASSWORD,
      });
      return;
    }

    setPasswordStatus({
      statusType: 'loading',
    });

    const response = await changeUserPassword(
      formState.values.currentPassword,
      formState.values.newPassword,
    );
    const responseError = handleResponseError(response);
    setPasswordStatus({});

    if (responseError) {
      setPasswordStatus({
        statusType: 'error',
        message: responseError,
      });
      return;
    }

    if (!response || !response.data) {
      setPasswordStatus({
        statusType: 'error',
        message: ERROR.RESPONSE_ERROR,
      });
      return;
    }

    setPasswordStatus({
      statusType: 'success',
      message: SUCCESS.PASSWORD_CHANGED_SUCCESSFULLY,
    });

    setTimeout(() => {
      setSession();
    }, 3000);
  };

  return (
    <Col md={10} lg={8} xl={6}>
      <Card className="border-0 shadow-sm">
        <Form onSubmit={updateProfile}>
          <CardHeader className="px-5 py-3 white-bg">
            <Row className="align-items-center">
              <Col>
                <h3 className="mb-0">My Profile</h3>
              </Col>
              <Col>
                <section className="d-flex">
                  <Button
                    type="submit"
                    className="ml-auto"
                    size="small"
                    loading={profileStatus.statusType === 'loading'}
                    disabled={formUnChanged()}
                  >
                    Save
                  </Button>
                </section>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-5 py-4 pb-5">
            {profileStatus && profileStatus.statusType === 'loading' ? (
              <Loader />
            ) : (
              ''
            )}
            {profileStatus && profileStatus.statusType === 'error' ? (
              <Alert
                color="danger"
                children={profileStatus.message}
                className="mb-4"
              />
            ) : (
              ''
            )}
            {profileStatus && profileStatus.statusType === 'success' ? (
              <Alert
                color="success"
                children={profileStatus.message}
                className="mb-4"
              />
            ) : (
              ''
            )}
            <Row>
              <Col sm={6}>
                <Input
                  label="First Name"
                  minLength={2}
                  {...text(formFields.firstName)}
                  required
                />
              </Col>
              <Col sm={6}>
                <Input
                  label="Last Name"
                  minLength={2}
                  {...text(formFields.lastName)}
                  required
                />
              </Col>
              <Col sm={12}>
                <Input
                  label="Email Address"
                  defaultValue={loggedInUser.email}
                  readOnly
                />
              </Col>
              <Col xs={12} className="mb-4">
                <Row className="align-items-center">
                  <Col xs={12} sm={3}>
                    <span>Role:</span>
                  </Col>
                  <Col xs={12} sm={8}>
                    {`${sentenceCase(loggedInUser.company)} ${sentenceCase(
                      loggedInUser.role,
                    )}`}
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row className="align-items-center">
                  <Col xs={12} sm={3}>
                    <span>Password:</span>
                  </Col>
                  <Col xs={12} sm={8}>
                    <Button color="gray" onClick={toggle}>
                      Change Password
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Form>
      </Card>

      <Modal isOpen={modal} returnFocusAfterClose={false} toggle={toggle} className="change-password">
        <ModalHeader
          toggle={toggle}
          className="p-4 d-flex justify-content-center"
          close={<span></span>}
          tag="h3"
        >
          Change Password
        </ModalHeader>
        <ModalBody className="p-4">
          <Form onSubmit={changePassword}>
            {passwordStatus && passwordStatus.statusType === 'loading' ? (
              <Loader />
            ) : (
              ''
            )}
            {passwordStatus && passwordStatus.statusType === 'error' ? (
              <Alert color="danger" children={passwordStatus.message} />
            ) : (
              ''
            )}
            {passwordStatus && passwordStatus.statusType === 'success' ? (
              <Alert color="success" children={passwordStatus.message} />
            ) : (
              ''
            )}
            <Input
              label="Current Password"
              {...password(formFields.currentPassword)}
              required
            />

            <Input
              label="New Password"
              {...password(formFields.newPassword)}
              required
            />

            <Input
              label="Confirm New Password"
              {...password({
                name: formFields.confirmPassword,
                validate: (value) =>
                  compareValues(value, formState.values.newPassword),
                validateOnBlur: true,
              })}
              errorMessage={
                formState.touched.confirmPassword &&
                !formState.validity.confirmPassword
                  ? ERROR.CONFIRM_PASSWORD
                  : ''
              }
              error={true}
              required
            />
            <Button
              type="submit"
              block
              loading={passwordStatus.statusType === 'loading'}
            >
              Change Password
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </Col>
  );
};

export default ProfileSettings;
